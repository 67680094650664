const initialState = {
    AdvertiserId: null,
    editting: false,
    loading: false
}

export const businessDetailsReducer = (state = initialState, action) => {

    switch (action.type) {

        case "GET_BUSINESS_DETAILS":
            return {
                ...state,
                loading: true
            }

        case "GET_BUSINESS_DETAILS_SUCCESS":
            let newState = action.data;
            newState.loading = false;
            newState.editting = false;
            return newState;

        case "GET_BUSINESS_DETAILS_FAILURE":
            return {
                ...state,
                err: action.err,
                loading: false
            }

        case "CREATE_BUSINESS_STARTED":
            return {
                ...state,
                loading: true
            }

        case "CREATE_BUSINESS_SUCCESS":
            let newAdvertiser = action.data;
            newAdvertiser.loading = false;
            newAdvertiser.editting = false;
            return newAdvertiser;

        case "CREATE_BUSINESS_FAILURE":
            return {
                ...state,
                err: action.err,
                loading: false
            }

        case "SET_ADVERTISER_DETS_SIGN_UP":
            return {
                ...state,
                AdvertiserId: action.id,
                Users: action.users,
                Name: action.name,
                loading: false
            }

        case "UPDATE_USERS":
            return {
                ...state,
                loading: true
            }

        case "UPDATE_USERS_FAILURE":
            return {
                ...state,
                err: action.message,
                loading: false
            }

        case "UPDATE_USERS_SUCCESS":
            return {
                ...state,
                Users: action.users,
                loading: false
            }

        case "UPDATE_FIELD":
            return {
                ...state,
                //loading: true
            }

        case "UPDATE_FIELD_FAILURE":
            return {
                ...state,
                err: action.message,
                loading: false
            }

        case "UPDATE_FIELD_SUCCESS":
            return {
                ...state,
                [action.fieldName]: action.value,
                loading: false
            }

        case "RESET":
            return initialState

        default:
            return state;
    }
}