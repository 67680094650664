import {createStore, compose, applyMiddleware} from 'redux';
import rootReducer from '../reducers';
import thunk from 'redux-thunk';
//import { composeWithDevTools } from "redux-devtools-extension";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose


export const configureStore = () => {
    const store = createStore(rootReducer,
        composeEnhancers(
            applyMiddleware(thunk)
        )
    );
        //composeWithDevTools(),applyMiddleware(thunk));
    return store;
}
