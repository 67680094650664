import React/*, { useEffect,useState }*/ from 'react';
import {useDispatch,useSelector} from 'react-redux';
import { useEffect } from 'react';
import 'react-nice-dates/build/style.css'
// import 'rc-time-picker/assets/index.css';
import './App.css';

import loadingGif from './img_resources/loading/test-pacman.gif'
import logo from './img_resources/coming-soon/logo.png'
import appStore from './img_resources/coming-soon/appstore.png'
import playStore from './img_resources/coming-soon/playstore.png'
import confeti from './img_resources/coming-soon/confetti.svg'
import stores from './img_resources/coming-soon/store.png'
import hands from './img_resources/coming-soon/hands.svg'
import banner from './img_resources/coming-soon/sign.png'

//components
// import Navbar from './components/Navbar';

//screens
// import SignInScreen from './Screens/SignInScreen.jsx';
// import Analysis from './Screens/Analysis';
// import Balance from './Screens/Balance';
// import Verify from './Screens/Verify';
// import Post from './Screens/Post';
// import ManageMenu from './Screens/ManageMenu';
// import History from './Screens/History';
// import Employee from './Screens/Employee'

//actions
// import {checkUser, updateUserAttribute, cleanUserAttributes, logOut} from './actions/userActions';
// import {getAds, setImg, resetAds} from './actions/adsActions';
// import {getBusinessDetails, getBusinessDetailsEmployee, createBusiness, resetBusinessDetails} from './actions/BusinessDetailsActions';
// import {setDisplay, verify} from './actions/displayActions'

//AWS
// import { Hub, Auth } from 'aws-amplify'
import Amplify from 'aws-amplify';
import imageCompression from 'browser-image-compression';
// import config from './aws-exports';

// import ZESTTY_BL from './innerBusinessLogic'

// const configureOAuthAWS = () =>{
//   // copied from serviceWorker.js to know if it is localhost or not
//   const isLocalhost = Boolean(
//     window.location.hostname === 'localhost' ||
//       // [::1] is the IPv6 localhost address.
//       window.location.hostname === '[::1]' ||
//       // 127.0.0.1/8 is considered localhost for IPv4.
//       window.location.hostname.match(
//         /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
//       )
//   );

//   // by default, say it's localhost
//   const oauth = {
//     domain: 'bezesttyeb155d18-eb155d18-devs.auth.us-east-2.amazoncognito.com',
//     scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
//     redirectSignIn: 'http://localhost:3000/',
//     redirectSignOut: 'http://localhost:3000/',
//     responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
//   };

//   // if not, update the URLs
//   if (!isLocalhost) {
//     oauth.redirectSignIn = 'https://www.bezestty.com/';
//     oauth.redirectSignOut = 'https://www.bezestty.com/';
//   }

  
//   // copy the constant config (aws-exports.js) because config is read only.
//   var configUpdate = config;
//   // update the configUpdate constant with the good URLs
//   configUpdate.oauth = oauth;

//   // Configure Amplify with configUpdate
//   Amplify.configure(configUpdate);
// }

// configureOAuthAWS();  

function App() {

  // const dispatch = useDispatch();

  // const user = useSelector(store=>store.user.user);
  // const display = useSelector(store=>store.display);
  // const currentAd = useSelector(store=>store.ads.activeAd)
  // const businessDetails = useSelector(store=>store.businessDetails);

  // const userLoader = useSelector(store=>store.user.loading);
  // const adsLoader = useSelector(store=>store.ads.loading);
  // const businessDetailsLoader = useSelector(store=>store.businessDetails.loading);

  // const imageCompressionOptions = {
  //   maxSizeMB: 0.7,
  //   maxWidthOrHeight: 1920,
  //   useWebWorker: false
  // }

  // //gets the business details based on the advertiser ID stored in the user data
  // useEffect(()=>{
  //   try{
  //     if(user.attributes["custom:type"].toLowerCase()==="Admin".toLowerCase()){
  //       console.log("THIS IS AN ADMIN USER");
  //       if(user.attributes["custom:firstLogin"]===true)
  //       {
  //         console.log("THIS IS THE FIRST LOGIN");
          
  //         let advertiser = ZESTTY_BL.Sign_Up.getAdvertiserObjFromUser(user)
  //         //create the business
  //         dispatch(createBusiness(advertiser));
  //         //get the details from the server
  //         dispatch(getBusinessDetails(user.attributes["custom:advertiserId"]));

  //       } else {
  //         console.log("GETTING BIZ DETS OF ", user.attributes["custom:advertiserId"])
          
  //         //this isn't the first login
  //         dispatch(getBusinessDetails(user.attributes["custom:advertiserId"]))
  //       }
  //     } else {
  //       console.log("THIS IS AN EMPLOYEE USER");
        
  //       //get business details for employee==>Check if user type matches server. Update if not.
  //       if (user.attributes["custom:advertiserId"]!=="null") {
          
  //         if(user.attributes["custom:type"].toLowerCase()==="employee".toLowerCase()){

  //           dispatch(getBusinessDetailsEmployee(user.attributes["custom:advertiserId"]))
  //           dispatch(setDisplay("EMPLOYEE", "Use your camera to scan customers QR codes"))

  //         } else if(user.attributes["custom:type"].toLowerCase()==="manager".toLowerCase()){
            
  //           dispatch(getBusinessDetails(user.attributes["custom:advertiserId"]));
  //           dispatch(setDisplay("MANAGE", "Posting a Zestty ad"))

  //         }
  //       }
        
  //     }
      
  //   } catch{
  //     console.log("ERROR WITH USER DETAILS OR NOT VERIFIED YET")
  //     if (!user.hasOwnProperty("pool")){
  //       dispatch(resetAds())
  //       dispatch(resetBusinessDetails())
  //     }
  //   }
    
  // }, [user])

  // //checks the user type vs. his type in the server.
  // //if the user belongs gets the relevant data
  // useEffect(()=>{
  //   if(businessDetails.hasOwnProperty("Id")){
      
  //     let action = "WAIT"


  //     if(user.attributes["custom:type"].toLowerCase()!=="Admin".toLowerCase()){
  //       action = handleNonAdminLogin();
  //     } else {
  //       action = "CONTINUE"
  //     }

  //     if (action==="CONTINUE"){

  //       attemptVerifyAd(businessDetails.Id);

  //       if(user.attributes["custom:type"].toLowerCase()==="Admin".toLowerCase()||user.attributes["custom:type"].toLowerCase()==="manager".toLowerCase()) {
  //         //ONLY RUN THIS IF THE USER IS MANAGER OR ADMIN
  //         dispatch(getAds(businessDetails.Id))
  //       }
        
  //       //if this is the users first login, and the business exists in the server, we can now set first login to false
  //       if(user.attributes["custom:firstLogin"]===true){
  //         dispatch(updateUserAttribute(user,'custom:firstLogin', "false"));
  //       }
  //     }
  //   }
  // }, [businessDetails.Id])

  // //if there a post is published, move to management screen.
  // //if the post is stopped, move to management screen
  // useEffect(()=>{
  //   if(currentAd.hasOwnProperty("Image")){
  //     if(display.display!=="VERIFY"){
  //       dispatch(setDisplay("MANAGE", "Management"))
  //     }
  //   }
  // }, [currentAd])


  // const handleNonAdminLogin = () =>{
  //   //this updates the user attributes based on the data in the server
  //   let action = ZESTTY_BL.Users.CompareUserToList(user,businessDetails.Users);

  //   switch (action.type){
  //     case "UPDATE":
  //       dispatch(updateUserAttribute(user, 'custom:type', action.data));
  //       return "WAIT"

  //     //if the user is not part of the business anymore, we clean his attributes
  //     case "CLEAN":
  //       alert("It seems you have been removed from the list of authorized users. Please speak with the business owner")
  //       dispatch(cleanUserAttributes(user))
  //       dispatch(logOut());
  //       return "STOP"

  //     case "LOGIN":
  //       console.log("Logging in...")
  //       return "CONTINUE"

  //     default:
  //       break;

  //   }
  // }

  // const attemptVerifyAd = (advertiserId) =>{
  //   let url = window.location.search;
  //   let params = new URLSearchParams(url);
  //   if(params.has('AdId') && params.has('UserId') && params.has('Token')){
  //     let adId = params.get('AdId');
  //     let userId = params.get('UserId');
  //     let token = params.get('Token');
      
  //     dispatch(setDisplay("VERIFY","Validating this QR code"));
  //     dispatch(verify(advertiserId,adId,userId,token))
      
  //   } else {
  //     console.log("NO VERIFY ACTION CALLED");
  //   }
  // }

  // const Screen = () =>{
  //   switch (display.display) {
  //     case "POST":
  //         return <Post/>

  //     case "MANAGE":
  //         return <ManageMenu/>

  //     case "HISTORY":
  //         return <History/>

  //     case "ANALYSIS":
  //         return <Analysis/>

  //     case "BALANCE":
  //         return <Balance/>

  //     case "VERIFY":
  //         return <Verify/>

  //     case "EMPLOYEE":
  //       return <Employee/>

  //     default:
  //       break;
  //   }
  // }
  
  // const newPostFileHandler = () => {
  //   if (!currentAd.hasOwnProperty('AdId')) {
  //     return (
  //       <input 
  //         type="file" 
  //         accept="image/*" 
  //         id="gallery" 
  //         style={{display:'none'}} 
  //         onChange={async(e)=>{
  //           try{
                
  //               var imageFile = e.target.files[0];
  //               let blob = await imageCompression(imageFile, imageCompressionOptions)
                
  //               var reader = new FileReader();
  //               reader.readAsDataURL(blob); 
  //               reader.onloadend = function() {
  //                   var base64Img = reader.result;                
  //                   dispatch(setImg(base64Img));
  //               }
  //               //let img = await ZESTTY_BL.Ads.toBase64(ZESTTY_BL.Ads.processFile(e.target.files[0],e.target.files[0].type))
  //               //dispatch(setImg(img));
  //           }
  //           catch {}
  //       }}/>
  //     )
  //   } else {
  //     return (<></>)
  //   }
    
  // }

  // const initializeApp = () =>{ 
  //   if (user.attributes["custom:type"].toLowerCase()==="Admin".toLowerCase()||user.attributes["custom:type"].toLowerCase()==="manager".toLowerCase()) {
  //     return (
  //       <>
  //         <Navbar/>
  //         {newPostFileHandler()}
  //         {Screen()}
  //       </>
  //     )
  //   } else if (user.attributes["custom:type"].toLowerCase()==="employee".toLowerCase()){
  //     return(
  //       <>
  //         {Screen()}
  //       </>
  //     )
  //   }
    
  // }

  // const checkIfLoggedIn = () =>{
  //   if (!user.hasOwnProperty("pool")) {
  //     dispatch(checkUser());
  //     return <SignInScreen/>
  //   } else {
  //     return initializeApp()
  //   }
  // }
  
  const userAgent = window.navigator.userAgent;

  useEffect(()=>{
    var now = new Date().valueOf();
    setTimeout(function () {
        if (new Date().valueOf() - now > 10000) return;
        if(userAgent.match(/iPhone/i) || userAgent.match(/Mac/i)){
          // window.location = "https://play.google.com/store/apps/details?id=com.zesttygroup.bezestty";
        } else {
          window.location = "https://play.google.com/store/apps/details?id=com.zesttygroup.bezestty";
          window.close();
        }
        
    }, 25);
    window.location = "bezestty://";
  },[])

  const generateLink = () =>{
    if(userAgent.match(/iPhone/i) || userAgent.match(/Mac/i)){
      window.location = "https://apps.apple.com/il/app/be-zestty/id1526115624";
    } else {
      window.location = "https://play.google.com/store/apps/details?id=com.zesttygroup.bezestty";
      window.close();
    }
    
  }

  return (
    <div className="coming-soon-bg" style={{backgroundImage:'url('+confeti+")"}}>
      <img className="coming-soon-logo" src={logo} alt="Be Zestty Logo"/>
      <img className="coming-soon-title" src={banner} alt="Coming Soon Banner"/>
      <img className="store-btn" src={stores} alt="app and playstore Button" onClick={()=>generateLink()}/>
      
      {/* <img className="store-btn" src={appStore} alt="Playstore Button"/> */}

      {/* <h2 className="slogan">
        Add some Zestt <br/> 
        to your business <br/> 
        & <br/> 
        bring in more customers!
      </h2> */}

      <p>For more information, <br/> please see <a href="https://www.zesttygroup.com/be-zestty">our website.</a></p>
      
      <img className="coming-soon-hands" src={hands} alt="Hands"/>
    </div>
    // <div className="App"> 
    //   {
    //     userLoader||adsLoader||businessDetailsLoader?
    //       <div className="loading-wrapper">
    //         <img src={loadingGif} alt="loading" className="loading-gif"/>
    //       </div>:
    //       <></>
    //   }

    //   {
    //     checkIfLoggedIn()
    //   }
    // </div>


  );
}

export default App;
