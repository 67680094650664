import { combineReducers } from 'redux';
import { userReducer } from './userReducer';
import { displayReducer } from './displayReducer';
import { adsReducer } from './adsReducer';
import { businessDetailsReducer } from './BusinessDetailsReducer'

const rootReducer = combineReducers({
    user: userReducer,
    display: displayReducer,
    ads: adsReducer,
    businessDetails: businessDetailsReducer
})

export default rootReducer;