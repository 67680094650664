const initialState = {
    display: "MANAGE",
    text: "Posting a Zestty ad",
    currency: "₪",
    verifying: false,
    loading: false
}

export const displayReducer = (state = initialState, action) => {

    switch (action.type) {


        case "SET_DISPLAY":
            return {
                ...state,
                display: action.display,
                adId: action.adId,
                text: action.text
            }

        case "OPEN_CAMERA":
            return {
                ...state,
                cameraOpen: true
            }

        case "CLOSE_CAMERA":
            return {
                ...state,
                cameraOpen: false
            }

        case "GET_CODE":
            return {
                ...state,
                loading: true
            }

        case "GET_CODE_FAILED":
            return {
                ...state,
                text: action.message,
                loading: false
            }

        case "VERIFY":
            return {
                ...state,
                verifying: true,
                loading: true
            }

        case "VERIFY_SUCCESS":
            return {
                ...state,
                display: "VERIFY",
                text: "Success",
                verifying: false,
                loading: false
            }

        case "VERIFY_FAILED":
            return {
                ...state,

                text: action.message,
                verifying: false,
                loading: false
            }

        default:
            return state;
    }
}