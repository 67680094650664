const initialState = {
    user: {
        username: null,
        advertiserId: null
    },
    password: null,
    stage: "sign in",
    loading: false
}

export const userReducer = (state = initialState, action) => {

    switch (action.type) {

        case "SET_STAGE":
            return {
                ...state,
                stage: action.stage
            }

        case "SIGN_UP_STARTED":
            return {
                ...state,
                loading: true
            }

        case "SIGN_UP_SUCCESS":
            return {
                ...state,
                user: {
                    ...state.user,
                    username: action.username
                },
                password: action.password,
                err: {},
                loading: false
            }

        case "SIGN_UP_FAIL":
            return {
                ...state,
                err: action.err,
                loading: false
            }

        case "LOGIN_STARTED":
            return {
                ...state,
                user: {
                    ...state.user,
                    username: action.username
                },
                password: action.password,
                loading: true
            }

        case "setUser":
            return {
                ...state,
                user: action.user,
                err: null,
                loading: false
            }

        case "LOGIN_FAILED":
            return {
                ...state,
                err: action.err,
                loading: false
            }

        case "SIGN_OUT_STARTED":
            return {
                ...state,
                loading: true
            }

        case "SIGN_OUT_SUCCESS":
            return initialState


        case "SIGN_OUT_FAIL":
            return {
                ...state,
                err: action.err,
                loading: false
            }

        case "VERIFY_STARTED":
            return {
                ...state,
                loading: true
            }

        case "VERIFY_SUCCESS":
            return {
                ...state,
                err: null,
                loading: false
            }

        case "VERIFY_FAILED":
            return {
                ...state,
                err: action.err,
                loading: false
            }

        case "UPDATE_ATTRIBUTE_STARTED":
            return {
                ...state,
                loading: true
            }

        case "UPDATE_ATTRIBUTE_SUCCESS":
            return {
                ...state,
                user: {
                    ...state.user,
                    attributes: action.userAttributes
                },
                err: null,
                loading: false
            }

        case "UPDATE_ATTRIBUTE_FAILED":
            return {
                ...state,
                err: action.err,
                loading: false
            }

        case "CLEAN_ATTRIBUTES_STARTED":
            return {
                ...state,
                loading: true
            }

        case "CLEAN_ATTRIBUTES_SUCCESS":
            return {
                ...state,
                user: {
                    ...state.user,
                    attributes: {
                        'custom:advertiserId': "null",
                        'custom:type': "null",
                        'custom:firstLogin': "true"
                    }
                },
                err: null,
                loading: false
            }

        case "CLEAN_ATTRIBUTES_FAILED":
            return {
                ...state,
                err: action.err,
                loading: false
            }


        default:
            return state;
    }
}