const initialState = {
    activeAd: {},
    newAd: {
        Image: "null"
    },
    ads: [],
    loading: false
}

export const adsReducer = (state = initialState, action) => {

    switch (action.type) {


        case "GET_ADS":
            return {
                ...state,
                loading: true
            }

        case "GET_ADS_SUCCESS":
            console.log("ADS ARE ", action.ads)
            return {
                ...state,
                activeAd: action.activeAd,
                ads: action.ads,
                loading: false
            }

        case "GET_ADS_FAILURE":

            return {
                ...state,
                err: action.message,
                loading: false
            }

        case "STOP_AD_STARTED":
            return {
                ...state,
                loading: true
            }

        case "STOP_AD_SUCCESS":

            return {
                ...state,
                activeAd: action.ad,
                loading: false
            }

        case "STOP_AD_FAILED":

            return {
                ...state,
                err: action.err,
                loading: false
            }

        case "UPLOAD_IMG_STARTED":

            return {
                ...state,
                loading: true
            }

        case "UPLOAD_IMG_SUCCESS":

            return {
                ...state,
                loading: false
            }

        case "UPLOAD_IMG_FAILED":

            return {
                ...state,
                err: action.message,
                loading: false
            }

        case "POST_AD":

            return {
                ...state,
                loading: true
            }

        case "POST_AD_SUCCESS":

            return {
                ...state,
                activeAd: action.ad,
                newAd: {},
                loading: false
            }

        case "POST_AD_FAILURE":

            return {
                ...state,
                activeAd: { editting: false },
                err: action.message,
                loading: false
            }

        case "SET_IMG":

            return {
                ...state,
                newAd: {
                    ...state.newAd,
                    Image: action.img
                }
            }

        case "SET_TEMPLATE":
            return {
                ...state,
                newAd: action.ad
            }

        case "RESET":
            return initialState
                // case "UPDATE_IMG":

            //     return {
            //         ...state,
            //         ad: {
            //             Image: action.img
            //         }
            //     }

            //case "UPDATE_TAGS_STARTED":

            //     return {
            //         ...state,
            //         loading: true
            //     }

            // case "UPDATE_TAGS_SUCCESS":
            //     console.log("INSIDE REDUCER UPDATE TAGS WITH", action.tags)
            //     return {
            //         ...state,
            //         activeAd: {
            //             ...state.activeAd,
            //             Tags: action.tags
            //         },
            //         loading: false
            //     }

            // case "UPDATE_TAGS_FAILURE":

            //     return {
            //         ...state,
            //         err: action.message,
            //         loading: false
            //     }

            // case "GET_ACTIVE_AD":
            //     return {
            //         ...state,
            //         loading: true
            //     }

            // case "GET_ACTIVE_AD_SUCCESS":

            //     return {
            //         ...state,
            //         activeAd: action.ad,
            //         loading: false
            //     }

            // case "GET_ACTIVE_AD_FAILURE":

            //     return {
            //         ...state,
            //         err: action.message,
            //         loading: false
            //     }
        default:
            return state;
    }
}